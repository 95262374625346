// See note below for why basename doesn't work cleanly
export const RouteUri = Object.freeze({
	// Unauth
	ROOT: '/',
	FOR_EMPLOYERS: '/employers',
	SALARY: '/salary',
	REVIEW: '/review',
	ABOUT_US: '/about',
	BLOG_LIST: '/blog',
	BLOG_SHOW: '/blog/:blogSlug/view',
	FAQ_LIST: '/faq',
	FAQ_ALL: '/faq/all',

	DATA: '/data',

	ADMIN: '/admin',
	ADMIN_CLINICIAN_SHOW: '/admin/clinician/:clinicianId/show',

	TOS: '/terms-of-service',
	PRIVACY_POLICY: '/privacy-policy',
	COMMUNITY_GUIDELINES: '/community-guidelines',
	FACILITY_INDEX: '/facility/:facilityId',
	FACILITY_SHOW: '/facility/:facilityId/view',
	FACILITY_REVIEW_LIST: '/facility/:facilityId/review',
	FACILITY_SALARY_LIST: '/facility/:facilityId/salary',
	FACILITY_JOB_LIST: '/facility/:facilityId/job',
	LOGIN: '/login',
	FORGOT_PASSWORD: '/forgot-password',
	RESET_PASSWORD: '/reset-password/:token',
	FINALIZE_SIGNUP: '/finalize-signup/:token',
	SUPPRESSION_LIST: '/setting/suppression/:token',

	// Search
	SEARCH_INDEX: '/emergency-medicine/:profession/:slug',
	SEARCH_PAGE: '/emergency-medicine/:profession/:slug/:page',

	// ACEP Accreditation
	ACCREDITATION_SEARCH_INDEX:
		'/acep-accreditation/:accreditation/:profession/:slug',
	ACCREDITATION_SEARCH_PAGE:
		'/acep-accreditation/:accreditation/:profession/:slug/:page',

	// Sitemap
	SITEMAP_INDEX: '/sitemap/:category',
	SITEMAP_PAGE: '/sitemap/:category/:page',

	// Authenticated
	CONNECTION: '/connection',
	SETTING: '/setting',
	FAVORITES: '/favorites',

	// Clinician
	EDIT_CLINICIAN: '/clinician/edit-profile',
	JOB_APPLICATION_LIST: '/job-application',

	// Organization
	CANDIDATE_LIST: '/candidate',
	CANDIDATE_SHOW: '/candidate/:candidateId/view',
	MANAGEMENT: '/organization/management',
	MANAGEMENT_FACILITY: '/organization/management/facility',
	MANAGEMENT_JOB: '/organization/management/job',
	MANAGEMENT_ACCOUNT: '/organization/management/account',
	MANAGEMENT_SETTING: '/organization/management/setting',
	ORG_INDEX: '/organization/:orgId',
	ORG_SHOW: '/organization/:orgId/view',
	ORG_REVIEW_LIST: '/organization/:orgId/review',
	ORG_SALARY_LIST: '/organization/:orgId/salary',
	ORG_FACILITY_LIST:
		'/organization/:orgId/facility/emergency-medicine/:profession/:slug',
	ORG_FACILITY_LIST_PAGE:
		'/organization/:orgId/facility/emergency-medicine/:profession/:slug/:page',

	// ACEP
	ACEP_ORG_SURVEY_CREATE: '/wl/acep/employer-survey/new',
	ACEP_ADMIN: '/wl/acep/admin',

	// EMRA
	EMRA_TRAINING_SURVEY_CREATE: '/wl/emra/training-survey/new',
	EMRA_RESIDENCY_SURVEY_SHOW: '/wl/emra/residency-survey/:surveyId/view',
	EMRA_CLERKSHIP_SURVEY_SHOW: '/wl/emra/clerkship-survey/:surveyId/view',
	EMRA_FELLOWSHIP_SURVEY_SHOW: '/wl/emra/fellowship-survey/:surveyId/view',
	EMRA_RESIDENCY_CREATE: '/wl/emra/residency/new',
	EMRA_RESIDENCY_SHOW: '/wl/emra/residency/:trainingId/view',
	EMRA_RESIDENCY_EDIT: '/wl/emra/residency/:trainingId/edit',
	EMRA_CLERKSHIP_CREATE: '/wl/emra/clerkship/new',
	EMRA_CLERKSHIP_SHOW: '/wl/emra/clerkship/:trainingId/view',
	EMRA_CLERKSHIP_EDIT: '/wl/emra/clerkship/:trainingId/edit',
	EMRA_FELLOWSHIP_CREATE: '/wl/emra/fellowship/new',
	EMRA_FELLOWSHIP_SHOW: '/wl/emra/fellowship/:trainingId/view',
	EMRA_FELLOWSHIP_EDIT: '/wl/emra/fellowship/:trainingId/edit',
	EMRA_ADMIN: '/wl/emra/admin',

	// Emra Search
	EMRA_SEARCH_INDEX: '/wl/emra/:type/:slug',
	EMRA_SEARCH_PAGE: '/wl/emra/:type/:slug/:page',

	JOB_POSTING_SHOW: '/posting/:postingId/view',
	JOB_POSTING_CREATE: '/posting/new',
	JOB_POSTING_EDIT: '/posting/:postingId/edit',

	// TODO: deprecate
	CLN_CONNECTION_LIST: '/clinician/connection',
	ORG_CONNECTION_LIST: '/organization/connection',
	ORG_CONNECTION_SHOW: '/organization/connection/:connectionId/view',
});

export const LinkUri = Object.freeze({
	NPI_LOOKUP: 'https://npiregistry.cms.hhs.gov',
	NPI_SIGNUP: 'https://nppes.cms.hhs.gov',
});

// Don't use window.location.origin - https://stackoverflow.com/questions/1368264/how-to-extract-the-hostname-portion-of-a-url-in-javascript
export const ORIGIN_NO_PORT = `${window.location.protocol}//${window.location.hostname}`;
